var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'issue-token gl-inline-flex gl-max-w-full gl-items-stretch gl-whitespace-nowrap gl-leading-24':
      _vm.isCondensed,
    'issuable-info-container flex-row': !_vm.isCondensed,
  }},[_c(_vm.computedLinkElementType,{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],ref:"link",tag:"component",class:{
      'issue-token-link gl-inline-flex gl-min-w-0 gl-text-gray-500': _vm.isCondensed,
      'issuable-main-info': !_vm.isCondensed,
    },attrs:{"href":_vm.computedPath,"title":_vm.issueTitle,"data-placement":"top"}},[(_vm.hasTitle)?_c(_vm.innerComponentType,{ref:"title",tag:"component",staticClass:"js-issue-token-title",class:{
        'issue-token-title issue-token-end gl-flex gl-items-baseline gl-overflow-hidden gl-pl-3 gl-text-gray-500':
          _vm.isCondensed,
        'issue-title block-truncated': !_vm.isCondensed,
        'gl-rounded-br-small gl-rounded-tr-small gl-pr-3': !_vm.canRemove,
      }},[_c('span',{staticClass:"gl-truncate"},[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_vm._v(" "),_c(_vm.innerComponentType,{ref:"reference",tag:"component",class:{
        'issue-token-reference gl-flex gl-items-center gl-rounded-bl-small gl-rounded-tl-small gl-px-3':
          _vm.isCondensed,
        'issuable-info': !_vm.isCondensed,
      }},[(_vm.hasState)?_c('gl-icon',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],class:_vm.iconClass,attrs:{"name":_vm.iconName,"size":12,"title":_vm.stateTitle,"aria-label":_vm.state,"data-testid":"referenceIcon"}}):_vm._e(),_vm._v("\n      "+_vm._s(_vm.displayReference)+"\n    ")],1)],1),_vm._v(" "),(_vm.canRemove)?_c('gl-button',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],ref:"removeButton",class:{
      'issue-token-remove-button !gl-rounded-l-none !gl-rounded-r-small gl-text-subtle':
        _vm.isCondensed,
    },attrs:{"title":_vm.removeButtonLabel,"aria-label":_vm.removeButtonLabel,"disabled":_vm.removeDisabled,"data-testid":"removeBtn","type":"button","category":"tertiary","size":"small","icon":"close"},on:{"click":_vm.onRemoveRequest}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }